import React, { FC, MouseEventHandler } from "react";

import { RiskFactorName } from "api/risk-service";
import { RISK_FACTORS_CONFIG } from "util/riskService";
import { FormattedText } from "components/atoms/FormattedText";

import S from "./styles";

export interface NoRiskCardProps {
  name: RiskFactorName;
  onMouseEnter: MouseEventHandler;
  onMouseLeave: MouseEventHandler;
}

const NoRiskCard: FC<NoRiskCardProps> = ({
  name,
  onMouseEnter,
  onMouseLeave
}) => {
  const getNoRiskText = () => {
    switch (name) {
      case RiskFactorName.PEPsRiskFactorName:
      case RiskFactorName.IntegrityCheckPEPsRCARiskFactorName:
        return "We have **not** identified your subject as a **PEP**.";
      case RiskFactorName.SanctionsRiskFactorName:
      case RiskFactorName.IntegrityCheckSanctionsRCARiskFactorName:
        return "We have **not** identified your subject as **sanctioned**.";
      case RiskFactorName.WatchlistsRiskFactorName:
      case RiskFactorName.IntegrityCheckWatchlistsRCARiskFactorName:
        return "We have **not** identified your subject on any **watchlists**.";
      default:
        return `We have **not** identified anything related to **${RISK_FACTORS_CONFIG[name].title}**.`;
    }
  };

  return (
    <S.Container onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <FormattedText text={getNoRiskText()} />
    </S.Container>
  );
};

export default NoRiskCard;
