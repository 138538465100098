import React, { ReactNode } from "react";

import S from "./styles";

interface RiskFrameworkHeaderProps {
  title: string;
  onMouseLeave?: () => void;
  controlComponent?: ReactNode;
}

export const RiskFrameworkHeader = ({
  title,
  onMouseLeave,
  controlComponent
}: RiskFrameworkHeaderProps) => {
  return (
    <S.HeaderContainer onMouseLeave={onMouseLeave}>
      <S.Title>{title}</S.Title>
      {controlComponent && controlComponent}
    </S.HeaderContainer>
  );
};
