import React, { useState } from "react";

import { RiskFactorName } from "api/risk";
import { RISK_FACTORS_CONFIG } from "util/riskService";
import { RiskReviewLabel } from "components/atoms/RiskReviewLabel";

import S from "./styles";

interface RiskIconProps {
  factor: RiskFactorName;
  isFlagged?: boolean;
  isMuted?: boolean;
  unconfirmedSourceCount?: number;
}

export const RiskIcon = ({
  factor,
  isFlagged,
  isMuted = false,
  unconfirmedSourceCount = 0
}: RiskIconProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const isForReview = unconfirmedSourceCount > 0;
  const { title, svg: Icon } = RISK_FACTORS_CONFIG[factor];

  const handleMouseEnter = () => {
    if (isFlagged || isForReview) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const isRca = [
    RiskFactorName.IntegrityCheckSanctionsRCARiskFactorName,
    RiskFactorName.IntegrityCheckWatchlistsRCARiskFactorName,
    RiskFactorName.IntegrityCheckPEPsRCARiskFactorName,
    RiskFactorName.IntegrityCheckSpecialInterestRCARiskFactorName
  ].includes(factor);

  return (
    <S.Container
      hasRisk={!!isFlagged && !isMuted}
      active={isHovered}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <S.IconContainer isRca={isRca}>
        <Icon />
      </S.IconContainer>
      <S.Title>{title}</S.Title>
      {isHovered && !isMuted ? (
        <S.DetailsLink>See details</S.DetailsLink>
      ) : (
        <S.Text>
          <RiskReviewLabel
            count={unconfirmedSourceCount}
            isMuted={isMuted}
            isFlagged={isFlagged}
            isForReview={isForReview}
            showInRed={isForReview && !isMuted}
          />
        </S.Text>
      )}
    </S.Container>
  );
};
