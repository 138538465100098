import React, { useEffect, useState, useRef } from "react";
import { REPORT_TYPES } from "util/reportTypes";
import { AnimatePresence, motion } from "framer-motion";

import { useInsightReport } from "util/hooks/useInsightReport";
import { useSubjectName } from "util/hooks/useSubjectName";
import { usePrintModeEnabled } from "util/hooks/useIsPrintModeEnabled";
import { InsightReportStatus } from "util/hooks/useInsightReport/types";

import InsightsChat from "components/organisms/InsightsChat";
import InsightsMessages from "components/organisms/InsightsChat/InsightsMessages";
import ContentNode from "components/organisms/InsightReport/ContentNode";
import TimelineSection from "components/organisms/InsightReport/TimelineSection";
import {
  getHasBeenDeletedOrMoved,
  getHasSubsections,
  hasOneSubsection
} from "components/organisms/InsightReport/utils";
import DeleteAnimationWrapper from "components/atoms/DeleteAnimationWrapper";
import useReportType from "util/hooks/useReportType";
import { isPDX } from "static-config";

import {
  shouldShowTimeline,
  useInsightsTimeline
} from "util/hooks/useInsightsTimeline";
import S from "./styles";
import DJDisclaimer from "./AdditionalContent/DJDisclaimer";

import Menu from "./Menu";

const TIMELINE_SLUG = "timeline";
const EXECUTIVE_SUMMARY_SLUG = "executive_summary";

const ReportContent = () => {
  const reportType = useReportType();
  const isLegalEntity = reportType === REPORT_TYPES.legalEntity;
  const { state } = useInsightReport();
  const { state: timelineState } = useInsightsTimeline();
  const subjectName = useSubjectName();
  const isPrintMode = usePrintModeEnabled();
  const [contentSectionHeight, setContentSectionHeight] = useState(0);

  const { report, activeSectionSlug } = state;

  const menuElementRef = useRef<HTMLUListElement>(null);
  const contentSectionRef = useRef<HTMLDivElement>(null);

  const activeSection = report
    ? report.sections.find(({ id }) => id === activeSectionSlug)
    : undefined;

  const hasActiveSectionBeenDeletedOrMoved = activeSection
    ? getHasBeenDeletedOrMoved({
        id: activeSection.id,
        nodeType: "div",
        state,
        parentIds: []
      })
    : true;

  const isPDXSummarySection =
    activeSectionSlug === EXECUTIVE_SUMMARY_SLUG && isPDX;

  useEffect(() => {
    contentSectionRef.current?.scroll({
      top: 0,
      behavior: "smooth"
    });
  }, [activeSectionSlug]);

  useEffect(() => {
    const updateContentSectionHeight = () => {
      if (menuElementRef.current) {
        setContentSectionHeight(menuElementRef.current.offsetHeight);
      }
    };

    const resizeObserver = new ResizeObserver(updateContentSectionHeight);

    if (menuElementRef.current) {
      resizeObserver.observe(menuElementRef.current);
    }

    updateContentSectionHeight();

    const menuElementRefCurrent = menuElementRef.current;

    return () => {
      if (menuElementRefCurrent) {
        resizeObserver.unobserve(menuElementRefCurrent);
      }
    };
  }, []);

  const showTimeline = shouldShowTimeline(timelineState, isPrintMode);
  const hasSubSections = getHasSubsections(activeSection);
  const isDeletingOrDeletedLastSubsection =
    hasOneSubsection(activeSection) &&
    (state.status === InsightReportStatus.removingReportSubSection ||
      state.status === InsightReportStatus.removingReportSectionSuccess) &&
    !state.elementIdToRemove;

  const showInlineInsightsChat =
    (!hasSubSections || isDeletingOrDeletedLastSubsection) && !isLegalEntity;

  const showBottomInsightsChat =
    hasSubSections &&
    !isDeletingOrDeletedLastSubsection &&
    !hasActiveSectionBeenDeletedOrMoved &&
    !!activeSection &&
    !isLegalEntity;

  return (
    <>
      {!isPrintMode && <Menu ref={menuElementRef} />}

      <S.ContentContainer>
        <S.ContentSection
          height={`${contentSectionHeight}px`}
          isPrintMode={isPrintMode}
          ref={contentSectionRef}
        >
          {isPrintMode ? (
            <>
              {report?.sections?.map(section =>
                section.content.map((content, index) => (
                  <S.ContentNodeContainer
                    key={`${section.slug}-${content.nodeType}-${index}`}
                  >
                    <ContentNode
                      subject={subjectName}
                      section={section.title}
                      {...content}
                    />
                  </S.ContentNodeContainer>
                ))
              )}
              {showTimeline && (
                <TimelineSection timeline={timelineState.timeline} />
              )}
            </>
          ) : (
            <AnimatePresence exitBeforeEnter>
              {activeSection && (
                <motion.div
                  key={`${activeSection.id}-container`}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  {activeSection.content.map((content, index) => (
                    <S.ContentNodeContainer
                      key={`${activeSection.id}-${content.nodeType}-${index}`}
                    >
                      <ContentNode
                        subject={subjectName}
                        section={activeSection.title}
                        {...content}
                      />
                    </S.ContentNodeContainer>
                  ))}
                  <InsightsMessages sectionId={activeSection.id} />

                  {showInlineInsightsChat && (
                    <S.InsightsChatContainer>
                      <InsightsChat sectionId={activeSection.id} showInput />
                    </S.InsightsChatContainer>
                  )}
                </motion.div>
              )}
              {showTimeline &&
                !activeSection &&
                activeSectionSlug === TIMELINE_SLUG && (
                  <motion.div
                    key="timeline-container"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <TimelineSection timeline={timelineState.timeline} />
                  </motion.div>
                )}
              <AnimatePresence>
                {showBottomInsightsChat && (
                  <DeleteAnimationWrapper>
                    <S.InsightsChatContainer>
                      <InsightsChat sectionId={activeSection.id} />
                    </S.InsightsChatContainer>
                  </DeleteAnimationWrapper>
                )}
              </AnimatePresence>
            </AnimatePresence>
          )}
          {isPDXSummarySection && <DJDisclaimer />}
        </S.ContentSection>
      </S.ContentContainer>
    </>
  );
};

export default ReportContent;
