import { type FC } from "react";
import { RiskFactorName, RiskLevel, RiskSection } from "api/risk";

import { ReactComponent as ESGSvg } from "img/icons/esg-icon.svg";
import { ReactComponent as RiskFlag } from "img/icons/flag-icon.svg";
import { ReactComponent as MajorCrimesSvg } from "img/icons/law-icon.svg";
import { ReactComponent as FinCrimeRiskFlag } from "img/icons/fin-crime.svg";
import { ReactComponent as SanctionsSvg } from "img/icons/sanctions-icon.svg";
import { ReactComponent as WatchlistsSvg } from "img/icons/watchlists-icon.svg";
import { ReactComponent as LocationRiskSvg } from "img/icons/location-risk.svg";
import { ReactComponent as RelatedPartiesSvg } from "img/icons/related-parties.svg";
import {
  ReactComponent as PepsSvg,
  ReactComponent as StateOwnedSvg
} from "img/icons/state-owned.svg";
import { ReactComponent as SpecialInterestSvg } from "img/icons/special-interest-icon.svg";
import { ReactComponent as RCAPepsSvg } from "themes/projectdx/img/icons/rca-peps-icon.svg";
import { ReactComponent as RCASanctionSvg } from "themes/projectdx/img/icons/rca-sanctions-icon.svg";
import { ReactComponent as RCAWatchlistSvg } from "themes/projectdx/img/icons/rca-watchlists-icon.svg";
import { ReactComponent as RCASpecialInterestSvg } from "themes/projectdx/img/icons/rca-specialinterest-icon.svg";

import highRiskAnimation from "animations/overallRiskScore/highRiskLoader.json";
import lowRiskAnimation from "animations/overallRiskScore/lowRiskLoader.json";

import { grey, red } from "styles/colors";

export const makePlural = (word: string, count: number, suffix: string = "s") =>
  `${word}${count > 1 ? suffix : ""}`;

type RiskFactorConfig = {
  [key in RiskFactorName]: {
    icon: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
      }
    >;
    title: string;
    description: string;
    getConfirmedTitle?: (count: number) => string;
  };
};

export const RISK_FACTOR_CONFIG: RiskFactorConfig = {
  [RiskFactorName.SanctionsRiskFactorName]: {
    icon: SanctionsSvg,
    title: "Sanctions",
    description:
      "Sanctions are measures imposed by governments and international organisations to restrict an organisation's financial activities.",
    getConfirmedTitle: (count: number) =>
      `${count} confirmed ${makePlural("sanction", count)}`
  },
  [RiskFactorName.IntegrityCheckSanctionsRCARiskFactorName]: {
    icon: RCASanctionSvg,
    title: "Sanctions",
    description:
      "Sanctions are measures imposed by governments and international organisations to restrict an organisation's financial activities."
  },
  [RiskFactorName.WatchlistsRiskFactorName]: {
    icon: WatchlistsSvg,
    title: "Watchlists",
    description:
      "Watchlists are databases of organisation's known or reasonably suspected of being involved in fraudulent or illegal activities.",
    getConfirmedTitle: (count: number) =>
      `${count} confirmed ${makePlural("sanction", count)}`
  },
  [RiskFactorName.IntegrityCheckWatchlistsRCARiskFactorName]: {
    icon: RCAWatchlistSvg,
    title: "Watchlists",
    description:
      "Watchlists are databases of individuals known or reasonably suspected of being involved in fraudulent or illegal activities."
  },
  [RiskFactorName.FinancialCrimeRiskFactorName]: {
    icon: FinCrimeRiskFlag,
    title: "Financial crime",
    description:
      "The risk level for this factor has been predefined for your organisation."
  },
  [RiskFactorName.LegalAndComplianceRiskFactorName]: {
    icon: MajorCrimesSvg,
    title: "Legal & compliance",
    description:
      "The risk level for this factor has been predefined for your organisation."
  },
  [RiskFactorName.ProceedingsRiskFactorName]: {
    icon: MajorCrimesSvg,
    title: "Proceedings",
    description:
      "The risk level for this factor has been predefined for your organisation."
  },
  [RiskFactorName.StateOwnershipRiskFactorName]: {
    icon: StateOwnedSvg,
    title: "State ownership",
    description:
      "Related organisations where a government or state holds significant control.",
    getConfirmedTitle: (count: number) =>
      `${count} confirmed ${makePlural("state ownership", count)}`
  },
  [RiskFactorName.ESGRiskFactorName]: {
    icon: ESGSvg,
    title: "ESG",
    description:
      "The risk level for this factor has been predefined for your organisation."
  },
  [RiskFactorName.RelatedPartiesRiskFactorName]: {
    icon: RelatedPartiesSvg,
    title: "Related parties",
    description:
      "The risk level is determined by the highest level of risk identified."
  },
  [RiskFactorName.OtherRiskFactorName]: {
    icon: RiskFlag,
    title: "Other controversy",
    description:
      "The risk level for this factor has been predefined for your organisation."
  },
  [RiskFactorName.LocationsRiskFactorName]: {
    icon: LocationRiskSvg,
    title: "Locations",
    description:
      "The risk level for this factor has been predefined for your organisation."
  },
  [RiskFactorName.PEPsRiskFactorName]: {
    icon: PepsSvg,
    title: "PEPs",
    description:
      "A Politically Exposed Person (PEP) is an individual entrusted with a prominent public function (or their immediate family).",
    getConfirmedTitle: (count: number) =>
      `${count} confirmed ${makePlural("PEP", count)}`
  },
  [RiskFactorName.IntegrityCheckPEPsRCARiskFactorName]: {
    icon: RCAPepsSvg,
    title: "PEPs",
    description:
      "A Politically Exposed Person (PEP) is an individual entrusted with a prominent public function (or their immediate family)."
  },
  [RiskFactorName.IncorporationDateRiskFactorName]: {
    icon: RiskFlag,
    title: "TBD",
    description: "TBD"
  },
  [RiskFactorName.CountryOfIncorporationRiskFactorName]: {
    icon: RiskFlag,
    title: "TBD",
    description: "TBD"
  },
  [RiskFactorName.BusinessSectorRiskFactorName]: {
    icon: RiskFlag,
    title: "TBD",
    description: "TBD"
  },
  [RiskFactorName.IntegrityCheckSpecialInterestRiskFactorName]: {
    icon: SpecialInterestSvg,
    title: "Special interest",
    description:
      "A Special Interest Person encompasses individuals implicated in specific types of criminal activity."
  },
  [RiskFactorName.IntegrityCheckSpecialInterestRCARiskFactorName]: {
    icon: RCASpecialInterestSvg,
    title: "Special interest",
    description:
      "A Special Interest Person encompasses individuals implicated in specific types of criminal activity."
  }
};

export const OVERALL_RISK_CONFIG = {
  [RiskLevel.HighRiskLevel]: {
    color: red.directRiskFill,
    label: "High",
    animationData: highRiskAnimation
  },
  [RiskLevel.MediumHighRiskLevel]: {
    color: red.directRiskFill,
    label: "Medium high",
    animationData: highRiskAnimation
  },
  [RiskLevel.MediumRiskLevel]: {
    color: red.directRiskFill,
    label: "Medium",
    animationData: highRiskAnimation
  },
  [RiskLevel.MediumLowRiskLevel]: {
    color: grey.darkish,
    label: "Medium low",
    animationData: lowRiskAnimation
  },
  [RiskLevel.LowRiskLevel]: {
    color: grey.darkish,
    label: "Low",
    animationData: lowRiskAnimation
  },
  [RiskLevel.NoneRiskLevel]: {
    color: grey.darkish,
    label: "Not identified",
    animationData: lowRiskAnimation
  }
};

export const RISK_LEVEL_CONFIG = {
  [RiskLevel.HighRiskLevel]: {
    title: "High overall risk",
    color: red.directRiskFill
  },
  [RiskLevel.MediumHighRiskLevel]: {
    title: "Medium high overall risk",
    color: red.directRiskFill
  },
  [RiskLevel.MediumRiskLevel]: {
    title: "Medium overall risk",
    color: red.directRiskFill
  },
  [RiskLevel.MediumLowRiskLevel]: {
    title: "Medium low overall risk",
    color: grey.darkish
  },
  [RiskLevel.LowRiskLevel]: {
    title: "Low overall risk",
    color: grey.darkish
  },
  [RiskLevel.NoneRiskLevel]: { title: "Not identified", color: grey.darkish }
};

export const getCardTitle = (type: RiskFactorName, numOfSummaries: number) => {
  const isPlural = numOfSummaries > 1;
  const pluralSuffix = isPlural ? "s" : "";

  switch (type) {
    case RiskFactorName.WatchlistsRiskFactorName:
    case RiskFactorName.IntegrityCheckWatchlistsRCARiskFactorName:
      return `${numOfSummaries} Confirmed watchlist${pluralSuffix}`;
    case RiskFactorName.SanctionsRiskFactorName:
    case RiskFactorName.IntegrityCheckSanctionsRCARiskFactorName:
      return `${numOfSummaries} Confirmed sanction${pluralSuffix}`;
    case RiskFactorName.StateOwnershipRiskFactorName:
      return `${isPlural ? `${numOfSummaries} ` : ""} State ownership ${
        isPlural ? "vehicles " : ""
      } confirmed`;
    case RiskFactorName.PEPsRiskFactorName:
    case RiskFactorName.IntegrityCheckPEPsRCARiskFactorName:
      return "PEPs";
    case RiskFactorName.FinancialCrimeRiskFactorName:
      return "Financial Crime";
    case RiskFactorName.LegalAndComplianceRiskFactorName:
      return "Legal & Compliance";
    case RiskFactorName.ProceedingsRiskFactorName:
      return "Proceedings";
    case RiskFactorName.ESGRiskFactorName:
      return "ESG";
    case RiskFactorName.OtherRiskFactorName:
      return "Other";
    case RiskFactorName.IntegrityCheckSpecialInterestRiskFactorName:
    case RiskFactorName.IntegrityCheckSpecialInterestRCARiskFactorName:
      return "Special Interest";
    default:
      return "";
  }
};

export const mapBucketToRiskLevel = (bucket: string) => {
  switch (bucket.toLocaleLowerCase()) {
    case "high":
      return RiskLevel.HighRiskLevel;
    case "medium_high":
      return RiskLevel.MediumHighRiskLevel;
    case "medium":
      return RiskLevel.MediumRiskLevel;
    case "medium_low":
      return RiskLevel.MediumLowRiskLevel;
    case "low":
      return RiskLevel.LowRiskLevel;
    default:
      return RiskLevel.NoneRiskLevel;
  }
};

export const SECTION_NAME_MAP: Record<RiskSection, string> = {
  [RiskSection.overallScore]: "Overall risk",
  [RiskSection.screeningFactors]: "Screening risk",
  [RiskSection.webAndMediaFactors]: "Web and media risk",
  [RiskSection.keyDetailsFactors]: "Key details factors"
};

const secondTextSuffix =
  "where there was insufficient information to confirm or discard them as your subject.";

export const RISK_REVIEW_CONFIG = {
  [RiskFactorName.SanctionsRiskFactorName]: {
    label: "sanction",
    description:
      "Sanctions are measures imposed by governments and international organisations to restrict an organisation's financial activities.",
    firstText: "We have **not** confirmed your subject as sanctioned.",
    getSecondText: (count: number) =>
      `However, we identified **${count} ${makePlural(
        "subject",
        count
      )} with a similar name on sanction lists**, ${secondTextSuffix}`,
    getSubjectText: (count: number) =>
      `${count} ${makePlural(
        "subject",
        count
      )} with a similar name on sanction lists`
  },
  [RiskFactorName.WatchlistsRiskFactorName]: {
    label: "watchlist",
    firstText: "We have **not** found your subject on a watchlist.",
    getSecondText: (count: number) =>
      `However, we identified **${count} ${makePlural(
        "subject",
        count
      )} with a similar name on watchlists**, ${secondTextSuffix}`,
    getSubjectText: (count: number) =>
      `${count} ${makePlural(
        "subject",
        count
      )} with a similar name on watchlists`
  },
  [RiskFactorName.PEPsRiskFactorName]: {
    label: "PEP",
    firstText: "We have **not** confirmed your subject as a PEP.",
    getSecondText: (count: number) =>
      `However, we identified **${count} ${makePlural(
        "subject",
        count
      )} with a similar name who are PEPs**, ${secondTextSuffix}`,
    getSubjectText: (count: number) =>
      `${count} ${makePlural(
        "subject",
        count
      )} with a similar name who are PEPs`
  }
};

export const RISK_FACTORS_CONFIG: Record<
  string,
  {
    key: string;
    title: string;
    svg: FC;
    activeTooltip: string;
    inactiveTooltip: string;
    showOnSummary: boolean;
  }
> = {
  sanctions: {
    key: "sanctions",
    title: "Sanctions",
    svg: SanctionsSvg,
    activeTooltip: "We have identified the subject as sanctioned",
    inactiveTooltip: "Sanctions",
    showOnSummary: true
  },
  watchlists: {
    key: "watchlists",
    title: "Watchlists",
    svg: WatchlistsSvg,
    activeTooltip: "We have identified the subject on a watchlist",
    inactiveTooltip: "Watchlists",
    showOnSummary: true
  },
  state_ownership: {
    key: "state_ownership",
    title: "State Ownership",
    svg: StateOwnedSvg,
    activeTooltip: "We have identified the subject as state owned",
    inactiveTooltip: "State Ownership",
    showOnSummary: true
  },
  financial_crime: {
    key: "financial_crime",
    title: "Financial Crime",
    svg: FinCrimeRiskFlag,
    activeTooltip: "Financial crime risk flagged for review",
    inactiveTooltip: "Financial Crime",
    showOnSummary: true
  },
  legal_and_compliance: {
    key: "legal_and_compliance",
    title: "Legal & Compliance",
    svg: MajorCrimesSvg,
    activeTooltip: "Legal & compliance risk flagged for review",
    inactiveTooltip: "Legal & Compliance",
    showOnSummary: true
  },
  related_parties: {
    key: "related_parties",
    title: "Related Parties",
    svg: RelatedPartiesSvg,
    activeTooltip: "Related Parties risk flagged for review",
    inactiveTooltip: "Related Parties",
    showOnSummary: true
  },
  relatedParties: {
    key: "relatedParties",
    title: "Related Parties",
    svg: RelatedPartiesSvg,
    activeTooltip: "Related Parties risk flagged for review",
    inactiveTooltip: "Related Parties",
    showOnSummary: false
  },
  locations: {
    key: "locations",
    title: "Locations",
    svg: LocationRiskSvg,
    activeTooltip: "High risk locations risk flagged for review",
    inactiveTooltip: "Locations",
    showOnSummary: true
  },
  esg: {
    key: "esg",
    title: "ESG",
    svg: ESGSvg,
    activeTooltip: "ESG risk flagged for review",
    inactiveTooltip: "ESG",
    showOnSummary: true
  },
  other: {
    key: "other",
    title: "Other Controversy",
    svg: RiskFlag,
    activeTooltip: "Other controversy flagged for review",
    inactiveTooltip: "Other Controversy",
    showOnSummary: true
  },
  peps: {
    key: "peps",
    title: "PEPs",
    svg: PepsSvg,
    activeTooltip: "We have identified your subject as a PEP",
    inactiveTooltip: "PEPs",
    showOnSummary: true
  },
  proceedings: {
    key: "proceedings",
    title: "Proceedings",
    svg: MajorCrimesSvg,
    activeTooltip: "Proceedings flagged for review",
    inactiveTooltip: "Proceedings",
    showOnSummary: true
  },
  incorporation_date: {
    key: "incorporation_date",
    title: "Incorporation date",
    svg: RiskFlag,
    activeTooltip: "Incorporation date flagged for review",
    inactiveTooltip: "Incorporation date",
    showOnSummary: false
  },
  country_of_incorporation: {
    key: "country_of_incorporation",
    title: "Country of incorporation",
    svg: RiskFlag,
    activeTooltip: "Country of incorporation flagged for review",
    inactiveTooltip: "Country of incorporation",
    showOnSummary: false
  },
  business_sector: {
    key: "business_sector",
    title: "Business sector",
    svg: RiskFlag,
    activeTooltip: "Business sector flagged for review",
    inactiveTooltip: "Business sector",
    showOnSummary: false
  },
  integrity_check_special_interest: {
    key: "integrity_check_special_interest",
    title: "Special interest",
    svg: SpecialInterestSvg,
    activeTooltip: "We have identified your subject as special interest",
    inactiveTooltip: "Special interest",
    showOnSummary: false
  },
  integrity_check_special_interest_rca: {
    key: "integrity_check_special_interest_rca",
    title: "Special interest RCA",
    svg: RCASpecialInterestSvg,
    activeTooltip: "We have identified your subject as special interest",
    inactiveTooltip: "Special interest",
    showOnSummary: false
  },
  integrity_check_sanctions_rca: {
    key: "integrity_check_sanctions_rca",
    title: "Sanctions",
    svg: RCASanctionSvg,
    activeTooltip: "We have identified the subject as sanctioned",
    inactiveTooltip: "Sanctions",
    showOnSummary: false
  },
  integrity_check_watchlists_rca: {
    key: "integrity_check_watchlists_rca",
    title: "Watchlists",
    svg: RCAWatchlistSvg,
    activeTooltip: "We have identified the subject on a watchlist",
    inactiveTooltip: "Watchlists",
    showOnSummary: true
  },
  integrity_check_peps_rca: {
    key: "integrity_check_peps_rca",
    title: "PEPs",
    svg: RCAPepsSvg,
    activeTooltip: "We have identified your subject as a PEP",
    inactiveTooltip: "PEPs",
    showOnSummary: false
  },
  rca: {
    key: "rca",
    title: "RCA",
    svg: RiskFlag,
    activeTooltip: "RCA flagged for review",
    inactiveTooltip: "RCA",
    showOnSummary: true
  }
};
