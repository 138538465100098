import { styled } from "@linaria/react";
import { fontSize } from "styles/fonts";
import { ChevronRight, ChevronLeft } from "react-feather";

const CarouselControls = styled.div<{ color: string }>`
  width: auto;
  display: flex;
  gap: 3px;
  align-items: baseline;
  font-size: ${fontSize.xs};
  background: transparent;
  color: ${({ color }) => color};
  justify-content: space-between;
  white-space: nowrap;
`;

const CarouselButton = styled.button<{ color: string }>`
  padding: 0px;
  border: none;
  cursor: pointer;
  background: transparent;
  transition: background 0.3s;
  color: ${({ color }) => color};

  :focus,
  :active {
    border: none;
    outline: none;
  }

  :disabled {
    visibility: hidden;
  }
`;

const CountDisplay = styled.div`
  padding-left: 2px;
`;

const ChevronLeftIcon = styled(ChevronLeft)`
  width: 16px;
  height: 16px;
  margin-top: -2px;

  path {
    fill: currentColor;
  }
`;

const ChevronRightIcon = styled(ChevronRight)`
  width: 16px;
  height: 16px;
  margin-top: -2px;

  path {
    fill: currentColor;
  }
`;

const S = {
  CarouselControls,
  CarouselButton,
  CountDisplay,
  ChevronLeftIcon,
  ChevronRightIcon
};

export default S;
