import React from "react";

import { grey, red } from "styles/colors";
import { RiskFactor } from "api/risk-service";

import S from "./styles";

interface RiskCarouselControlProps {
  index: number;
  iconsToShow: number;
  iconData: Array<RiskFactor>;
  handlePrev: () => void;
  handleNext: () => void;
}

const isIconRiskForRange = (
  iconData: Array<RiskFactor>,
  start: number,
  end: number
) =>
  iconData.slice(start, end).find(icon => icon.flagged && !icon.dateMuted) !==
  undefined;

export const RiskCarouselControl = ({
  index,
  iconsToShow,
  iconData,
  handlePrev,
  handleNext
}: RiskCarouselControlProps) => {
  const totalItems = iconData.length;
  const isRiskInIconsToTheLeft = isIconRiskForRange(iconData, 0, index);
  const isRiskInIconsToTheRight = isIconRiskForRange(
    iconData,
    index + iconsToShow,
    totalItems
  );

  const isLeftButtonRequired = index > 0;
  const isRightButtonRequired = index + iconsToShow < totalItems;
  const notDisplayedIconsCount = totalItems - iconsToShow;

  const controllerColor =
    isRiskInIconsToTheLeft || isRiskInIconsToTheRight
      ? red.directRiskFill
      : grey.dark;

  return (
    <S.CarouselControls color={controllerColor}>
      <S.CarouselButton
        onClick={handlePrev}
        color={isRiskInIconsToTheLeft ? red.directRiskFill : grey.dark}
        disabled={!isLeftButtonRequired}
      >
        <S.ChevronLeftIcon />
      </S.CarouselButton>
      <S.CountDisplay>{`${notDisplayedIconsCount} more`}</S.CountDisplay>
      <S.CarouselButton
        onClick={handleNext}
        color={isRiskInIconsToTheRight ? red.directRiskFill : grey.dark}
        disabled={!isRightButtonRequired}
      >
        <S.ChevronRightIcon />
      </S.CarouselButton>
    </S.CarouselControls>
  );
};
