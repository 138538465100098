import React, { Fragment, useState, type FC } from "react";
import { type CollectionIndividualItem as CollectionIndividualItemType } from "util/hooks/useCollectionList/types";
import { CardVariant } from "components/molecules/Card/types";
import { CollectionListView } from "components/molecules/CollectionListControls";

import S from "./styles";

interface Props {
  item: CollectionIndividualItemType;
  CardComponent: FC<any>;
  cardVariant: CardVariant;
  id: string;
  view: CollectionListView;
}

const CollectionIndividualItem: FC<Props> = ({
  item,
  CardComponent,
  cardVariant,
  id,
  view
}) => {
  const [showNestedCards, setShowNestedCards] = useState(false);
  const handleToggleNestedCards = () => setShowNestedCards(prev => !prev);

  const hasNestedCards = "children" in item && item.children.length > 0;
  const hasUnreadNestedCards =
    hasNestedCards && item.children.some(({ isUnread }) => isUnread);

  if (hasNestedCards) {
    return (
      <Fragment key={`CollectionCard-${item.id}`}>
        <S.CardWithNestedCardsContainer>
          <CardComponent
            view={view}
            item={item}
            variant={cardVariant}
            id={id}
          />

          <S.NestedCardToggle onClick={handleToggleNestedCards}>
            <S.ChevronIcon isActive={showNestedCards} />
            {hasUnreadNestedCards && (
              <S.UnreadIndicator variant={cardVariant} />
            )}
            {item.children.length} related parties
          </S.NestedCardToggle>
        </S.CardWithNestedCardsContainer>

        {showNestedCards &&
          item.children.map((childItem, i) => (
            <S.NestedCardContainer
              key={`CollectionCard-${childItem.id}`}
              isFirstCard={i === 0}
            >
              <CardComponent
                view={view}
                item={childItem}
                variant={cardVariant}
                id={id}
              />
            </S.NestedCardContainer>
          ))}
      </Fragment>
    );
  }

  return (
    <CardComponent
      key={`CollectionCard-${item.id}`}
      view={view}
      item={item}
      variant={cardVariant}
      id={id}
    />
  );
};

export default CollectionIndividualItem;
