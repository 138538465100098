import { styled } from "@linaria/react";

const SectionContainer = styled.div<{ isCarousel?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: ${({ isCarousel }) => (isCarousel ? "1 1 auto" : "0 0 auto")};
  min-width: 0px;
  background: white;
  padding: 16px;
`;

const FixedIconContainer = styled.div<{ showMultipleIcons?: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  flex: 1 0 auto;
  gap: ${({ showMultipleIcons }) => (showMultipleIcons ? 16 : 0)}px;
  justify-content: ${({ showMultipleIcons }) =>
    showMultipleIcons ? "space-between" : "center"};
`;

const Icon = styled.div`
  font-size: 2rem; /* Icon size */
  padding: 12px 0;
`;

const S = {
  Icon,
  FixedIconContainer,
  SectionContainer
};

export default S;
