import React, { ReactNode, FC } from "react";

import { AnimatePresence } from "framer-motion";

import CollectionCount from "components/molecules/CollectionCount";
import CollectionListControls, {
  CollectionListView
} from "components/molecules/CollectionListControls";
import CollectionIndividualItem from "components/organisms/CollectionIndividualItem";
import { CardVariant } from "components/molecules/Card/types";

import { CollectionStatus } from "util/hooks/useCollectionList/types";
import type { CollectionIndividualItem as CollectionIndividualItemType } from "util/hooks/useCollectionList/types";

import CollectionEnd from "./CollectionEnd";
import CollectionStart from "./CollectionStart";
import LoadingCollectionCards from "./LoadingCollectionCards";

import S from "./styles";

interface Props {
  id: string;
  title?: string;
  status: CollectionStatus;
  items: CollectionIndividualItemType[];
  view: CollectionListView;
  showCount: boolean;
  showTitle: boolean;
  showTitleCount: boolean;
  sortable: boolean;
  navigationComponent?: ReactNode;
  createItemComponent?: ReactNode;
  toggles?: ReactNode;
  cardVariant?: CardVariant;
  CardComponent: FC<any>;
  totalItemCount: number;
  filterItemsFn?: (item: CollectionIndividualItemType) => boolean;
  emptyCollectionText?: string;
  hiddenIfEmpty: boolean;
}

const CollectionItem: FC<Props> = ({
  id,
  title,
  status,
  items,
  view,
  showCount,
  showTitle,
  showTitleCount,
  sortable,
  navigationComponent,
  createItemComponent,
  toggles,
  cardVariant = CardVariant.default,
  CardComponent,
  totalItemCount,
  filterItemsFn,
  emptyCollectionText = "No results",
  hiddenIfEmpty
}) => {
  const itemsToDisplay = filterItemsFn ? items.filter(filterItemsFn) : items;

  if (itemsToDisplay.length === 0 && hiddenIfEmpty) {
    return null;
  }

  const displayTitle = showTitleCount ? `${title} (${totalItemCount})` : title;

  return (
    <S.CollectionListOuterContainer>
      <S.CollectionNavigationContainer>
        {navigationComponent}
        {status !== CollectionStatus.loading && showCount && title && (
          <CollectionCount title={title} count={totalItemCount} />
        )}
      </S.CollectionNavigationContainer>
      <CollectionListControls
        id={id}
        title={showTitle ? displayTitle : undefined}
        sortable={sortable}
        createItemComponent={createItemComponent}
        toggles={toggles}
      />
      <S.CollectionListContainer>
        {itemsToDisplay.length === 0 && status === CollectionStatus.loading ? (
          <LoadingCollectionCards
            view={view}
            CardComponent={CardComponent}
            cardVariant={cardVariant}
          />
        ) : (
          <>
            <CollectionStart id={id} />
            {!itemsToDisplay.length ? (
              <S.NoResults>{emptyCollectionText}</S.NoResults>
            ) : null}

            <AnimatePresence>
              {itemsToDisplay.map(item => {
                return (
                  <CollectionIndividualItem
                    key={`CollectionCard-${item.id}`}
                    id={id}
                    cardVariant={cardVariant}
                    item={item}
                    CardComponent={CardComponent}
                    view={view}
                  />
                );
              })}
            </AnimatePresence>
            {itemsToDisplay.length > 0 &&
              status === CollectionStatus.loading && (
                <LoadingCollectionCards
                  view={view}
                  CardComponent={CardComponent}
                  cardVariant={cardVariant}
                />
              )}
            {(totalItemCount && itemsToDisplay.length < totalItemCount && (
              <CollectionEnd id={id} />
            )) ||
              undefined}
          </>
        )}
      </S.CollectionListContainer>
    </S.CollectionListOuterContainer>
  );
};

export default CollectionItem;
