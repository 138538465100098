import React from "react";
import Lottie from "lottie-react";
import { RiskLevel } from "api/risk";
import { OVERALL_RISK_CONFIG } from "util/riskService";
import { ReactComponent as SpecialInterestIconSvg } from "img/icons/special-interest-icon.svg";

import S from "./styles";

interface OverallRiskScoreIconProps {
  score: RiskLevel;
  isWrapped?: boolean;
  isRecalculating?: boolean;
}

export const OverallRiskScoreIcon = ({
  score,
  isWrapped = false,
  isRecalculating = false
}: OverallRiskScoreIconProps) => {
  const { color, label, animationData } = OVERALL_RISK_CONFIG[score];

  const icon = isRecalculating ? (
    <Lottie animationData={animationData} />
  ) : (
    <SpecialInterestIconSvg />
  );

  return (
    <S.Container color={color} active={isWrapped}>
      <S.IconContainer>{icon}</S.IconContainer>
      <S.Title>{isRecalculating ? "Recalculating..." : label}</S.Title>
      <S.EmptyPlaceholder />
    </S.Container>
  );
};
