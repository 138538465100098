import { grey } from "styles/colors";
import { styled } from "@linaria/react";
import { family, fontSize } from "styles/fonts";

const HeaderContainer = styled.div`
  display: flex;
  gap: 4px;
  width: 100%;
  margin: 4px;
  font-weight: bold;
  color: ${grey.mid};
  align-items: center;
  font-size: ${fontSize.sm};
  justify-content: space-between;
  font-family: ${family.interDisplay};
`;

const Title = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const S = { HeaderContainer, Title };

export default S;
